import React from 'react';
import logo from '../images/chetwynd-logo.jpg';
import Navbar from './Navbar';


const Header = () => (
  <div>
    <div className = "page-header">
      <img src={logo} alt="Logo" />
      <h1>Chetwynd Tennis Club</h1>
    </div>
    <div className={"wrapper"}>
        <div className="navbar"><Navbar /></div>
    </div>
  </div>
);

export default Header;
