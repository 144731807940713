import React from 'react';
import Layout from '../components/Layout';
import HomeSlides from '../components/homeSlideshow';
import membership from './ChetwyndApplication.pdf'

const Home = () => (
  <div >
    <Layout>
    <div className={""}>
      <div className={"slideshow"}><HomeSlides /></div>
    </div>
      <div className={"content"}>
        <ul>
          <li>
            <h1>Mission Statement</h1>
            <p>
              To be the leading Tennis Club in Trinidad and Tobago with a commitment 
              to the needs of our membership through the expansion of our local and 
              international affiliations.
            </p>
            <h1><a href={membership} download>Click for Chetwynd Membership Application form</a></h1>
          </li>
      </ul>
    </div>
    </Layout>
  </div>
);

export default Home;
