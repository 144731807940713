import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';


const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          id
          siteMetadata {
            description
            title
          }
        }
      }
    `,
  );
  return (
    <div className = "page-footer">
      <h6>
        Copyright {new Date().getFullYear()}, Chetwynd Tennis Club. All rights reserved.
      </h6>

    </div>
  );
};

export default Footer;
