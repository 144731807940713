import React from "react"
import { Link } from "gatsby"

const Navbar = () => (
  < div className = "navbar" >
    <ul >
      <li >
        <Link to="/">Home</Link>
      </li>
      <li >
        <Link to="/tournament">Senior Tournament</Link>
      </li>
      <li >
        <Link to="/gallery">Gallery</Link>
      </li>
      <li >
        <Link to="/events">Events</Link>
      </li>
      <li >
        <Link to="/about">About US</Link>
      </li>
      <li >
        <Link to="/contact">Contact US</Link>
      </li>
    </ul> 
  </div>
  
)

export default Navbar;