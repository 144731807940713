import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../scss/main.scss';

const Layout = ({ children }) => (
  <div className={"body"}>
    <div className={"wrapper"}>
      <div className="page-header"><Header /></div>
      
      <div className={"content"}>{children}</div>
      <div className="page-footer"><Footer /></div>
    </div>
  </div>
);

export default Layout;
